import { Navigate, NavigateProps, Params, useParams } from 'react-router-dom';

interface NavigateWithParamsProps extends Omit<NavigateProps, 'to'> {
    to: string | ((params: Readonly<Params<string>>) => string);
}

export function NavigateWithParams({ to, ...rest }: NavigateWithParamsProps) {
    const params = useParams();
    let toValue: string;
    if (typeof to === 'function') {
        toValue = to(params);
    } else {
        toValue = to;
    }
    return <Navigate to={toValue} {...rest} />;
}
